import React from 'react'
import Link from '@material-ui/core/Link'
import Header from '../components/layout/Header'
import MainWrapper from '../components/layout/Main'
import Footer from '../components/layout/Footer'

import { FaGithub, FaTwitter } from 'react-icons/fa'
import LocalCafe from '@material-ui/icons/LocalCafe'


const style = {
    icon:{
        position: 'relative',
        top: '6px',
        marginRight: '15px'
    }
}

export default function Contact() {
    return (
        <div className='outter_wrapper'>
            <Header pageTitle='Contact'/>
            <MainWrapper> 
                <h1> Say hi! </h1>  
                <ul>
                    <li><LocalCafe style={style.icon} /><Link href="mailto: jeanloureiro@gmail.com">jeanloureiro@gmail.com</Link></li>
                    <li><FaTwitter style={style.icon} /><Link href="https://twitter.com/JeanLFA" target="_blank" rel="noopener noreferrer">@JeanLFA</Link></li>
                    <li><FaGithub style={style.icon} /><Link href="https://github.com/JeanLoureiro" target="_blank" rel="noopener noreferrer">JeanLoureiro</Link></li>
                </ul>
            </MainWrapper>
            <Footer />

        </div>
    )
}

